import { getFieldValue } from '../../utils-hooks/credential-utils'
import { client } from '../client'
import {
  ACSTransaction,
  Credential,
  CredentialFormat_Read,
  CredentialStatus,
  CredentialWithFields,
  Credential_Read,
} from '../models'

export const fetchCredentials = async (profileId: string) => {
  return client.get<Credential[]>('/credentials', {
    params: {
      'base-id': profileId,
      select: 'credential_id,credential_format_id,credential_fields',
    },
  })
}

const fetchCredential = async (id: string) => {
  return client
    .get<Credential_Read>(`/credentials/${id}`)
    .then((res) => res.data)
}

const fetchCredentialFormat = async (id: string) => {
  return client
    .get<CredentialFormat_Read>(`/credential-formats/${id}`)
    .then((res) => res.data)
}

export const fetchCredentialWithFields = async (
  credential: Credential,
): Promise<CredentialWithFields> => {
  const [fullCredential, format] = await Promise.all([
    fetchCredential(credential.credential_id),
    fetchCredentialFormat(credential.credential_format_id),
  ])

  // Extract the values from the fields
  const fields = {
    facilityCode: getFieldValue(
      fullCredential,
      format,
      'facility_code',
    ) as number,
    cardId: getFieldValue(fullCredential, format, 'card_id') as number,
    status: getFieldValue(fullCredential, format, 'status') as CredentialStatus,
  }

  return {
    ...fullCredential,
    fields,
  }
}

export const putCredential = async (credential: Credential_Read) => {
  return client.put<Credential_Read>(
    `/credentials/${credential.credential_id}`,
    credential,
  )
}

export const putCredentialFromPartial = async (credential: Credential) => {
  const fullCredential = await fetchCredential(credential.credential_id)

  const payload = {
    ...fullCredential,
    ...credential,
  }

  return putCredential(payload)
}

export const fetchTransactions = async (
  entityId: string,
  profileId: string,
) => {
  return client
    .get<ACSTransaction[]>('/acs-transactions', {
      params: {
        'base-id': entityId,
        filters: `profile_id EQ ${profileId}`,
        select: 'result,epi_name,event_at',
      },
    })
    .then((res) => res.data)
}
